<template>
<div id="channel_price_upd_log">
    <!-- 通道详情-弹出层 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>通道详情</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-row class="dialog_top cursor" style="text-align: left;padding-left:15px;">
                    <span>基本信息</span>
                    <el-button type="text" size="mini" style="float: right;margin-right: 20px;margin-top: 8px;"  @click="to_channel()">跳转到通道</el-button>
                </el-row>
                <!-- 基本信息 -->
                <el-table v-if="show_base" :data="tableData_base" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="140"  align="center"> </el-table-column>
                    <!--  <el-table-column  prop="show_value"  min-width="100" align="right"> </el-table-column>  -->
                    <el-table-column  min-width="100" align="right">
                        <template slot-scope="scope">
                            <template v-if="scope.row.name == 'send_citys_array'">
                                <span v-html="scope.row.show_value"></span>
                            </template>
                            <template v-else>
                                {{scope.row.show_value}}
                            </template>
                        </template>
                    </el-table-column>
                </el-table>

                
                
                                    
            </el-row>
            
        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_channel_id:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度



            show_base:true,//通道基本信息 是否展示  true:展示
            tableData_base:[],//通道基本信息数据
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
        },
         //数据清除
        dataClear(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
        },
        //跳转到通道
        to_channel(){
            API.router_to("/channel_detail/"+this.prop_channel_id);
        },

        //查询
        getData(){

            var id = this.prop_channel_id;//通道id
            //请求接口
            API.ChannelServlet({
                param: "detail",
                channel_id:id,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.detail_obj = res.detail;
                    var show_value = "";

                    var table_obj = {label:"通道ID",show_value:res.detail.channel_id,name:"channel_id"};
                    this.tableData_base.push(table_obj);

                    if(1 == 1){
                        
                        table_obj = {label:"通道名",show_value:res.detail.channel_name,name:"channel_name"};
                        this.tableData_base.push(table_obj);

                        show_value = "";
                        var opers = res.detail.oper.split(",");
                        for (let oper of opers) {
                            if(show_value != ""){
                                show_value += ",";
                            }
                            if(oper == "0"){
                                show_value += "移动";
                            }else if(oper == "1"){
                                show_value += "联通";
                            }else if(oper == "2"){
                                show_value += "电信";
                            }
                        }
                        table_obj = {label:"可发运营商",show_value:show_value,name:""};
                        this.tableData_base.push(table_obj);

                        if(opers.indexOf("0") > -1){
                            table_obj = {label:"移动可发省份",show_value:res.detail.cm_province_str,name:""};
                            this.tableData_base.push(table_obj);
                             if(res.detail.cm_send_citys_array.length > 0){
                                table_obj = {label:"移动可发地市",show_value:res.detail.cm_send_citys_array_str,value:res.detail.cm_send_citys_array,name:"send_citys_array"};
                                this.tableData_base.push(table_obj);
                            }
                        }
                        if(opers.indexOf("1") > -1){
                            table_obj = {label:"联通可发省份",show_value:res.detail.cu_province_str,name:""};
                            this.tableData_base.push(table_obj);
                            if(res.detail.cu_send_citys_array.length > 0){
                                table_obj = {label:"联通可发地市",show_value:res.detail.cu_send_citys_array_str,value:res.detail.cu_send_citys_array,name:"send_citys_array"};
                                this.tableData_base.push(table_obj);
                            }
                        }
                        if(opers.indexOf("2") > -1){
                            table_obj = {label:"电信可发省份",show_value:res.detail.ct_province_str,name:""};
                            this.tableData_base.push(table_obj);
                            if(res.detail.ct_send_citys_array.length > 0){
                                table_obj = {label:"电信可发地市",show_value:res.detail.ct_send_citys_array_str,value:res.detail.ct_send_citys_array,name:"send_citys_array"};
                                this.tableData_base.push(table_obj);
                            }
                        }

                        show_value = "";
                        if(res.detail.channel_style == 0){
                            show_value = "短信通道";
                        }else if(res.detail.channel_style == 1){
                            show_value = "语音通道";
                        }else if(res.detail.channel_style == 2){
                            show_value = "多媒体短信通道";
                        }else if(res.detail.channel_style == 3){
                            show_value = "固话通道";
                        }
                        table_obj = {label:"通道类型",show_value:show_value,name:""};
                        this.tableData_base.push(table_obj);
                        if(res.detail.channel_style == 1){
                            table_obj = {label:"语音价格",show_value:res.detail.channel_voice_str,name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"当日已发送条数",show_value:res.detail.moNum+ '条',name:""};
                        this.tableData_base.push(table_obj);

                        

                        table_obj = {label:"服务号码",show_value:res.detail.specialnum,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"短信数据统计",show_value:res.detail.alarm == 0 ? '不预警' : '预警',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"签名位置",show_value:res.detail.sign_position == 0 ? '强制前置' : res.detail.sign_position == 1 ? '强制后置' :'不管签名位置',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"签名库",show_value:res.detail.sign_name,name:""};
                        this.tableData_base.push(table_obj);
                        if(res.detail.sign_library_id > 0){
                            table_obj = {label:"签名扩展类型",show_value:res.detail.sign_ext_onoff == 0 ? '使用签名库扩展' : res.detail.sign_position == 1 ? '使用签名库扩展+客户扩展' :'使用客户扩展',name:""};
                            this.tableData_base.push(table_obj);
                            table_obj = {label:"后报备签名",show_value:res.detail.sign_after == 0 ? '允许' : '不允许',name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"通道状态",show_value:res.detail.onoff == 0 ? '关闭' : '打开',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否支持携号转网",show_value:res.detail.change_oper_onoff == 0 ? '不支持' : '支持',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否信任",show_value:res.detail.trust_onoff == 0 ? '不信任' : '信任',name:""};
                        this.tableData_base.push(table_obj);

                        show_value = "";
                        var send_types = res.detail.send_type.split(",");
                        for (let v of send_types) {
                            if(show_value != ""){
                                show_value += ",";
                            }
                            if(v == "1"){
                                show_value += "验证码";
                            }else if(v == "2"){
                                show_value += "行业";
                            }else if(v == "3"){
                                show_value += "营销";
                            }else if(v == "4"){
                                show_value += "四类";
                            }
                        }
                        table_obj = {label:"下发短信类型",show_value:show_value,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否允许带链接",show_value:res.detail.link_is == 0 ? '允许' : '不允许',name:""};
                        this.tableData_base.push(table_obj);
                        if(res.detail.link_is == 0 ){
                            table_obj = {label:"带链接可发级别",show_value:res.detail.link_trust_level == 0 ? '可发信任的客户' : '可发不信任的客户',name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"是否支持长短信",show_value:res.detail.isLong == 0 ? '支持' : '不支持',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否支持上行",show_value:res.detail.isMo == 0 ? '支持' : '不支持',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"支持的最大扩展位数",show_value:res.detail.ext_max,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"支持的特殊标签",show_value:res.detail.special_label,name:""};
                        this.tableData_base.push(table_obj);

                        if(res.detail.special_label != ""){
                            table_obj = {label:"特殊标签的作用范围",show_value:res.detail.special_label_type == 0 ? '可发此特殊标签' : '仅能发此特殊标签',name:""};
                            this.tableData_base.push(table_obj);
                        }

                        var supplier_type = res.detail.supplier_type;
                        table_obj = {label:"供应商类别",show_value:supplier_type == 0 ? '直连' : supplier_type == 1 ? '准直连' : supplier_type == 2 ? '三方' : '厂商',name:""};
                        this.tableData_base.push(table_obj);

                        if(res.detail.supplier_type == 3){//厂商
                            table_obj = {label:"厂商类别",show_value:res.detail.firm_type == 0 ? '小米' : 'oppo',name:""};
                            this.tableData_base.push(table_obj);
                        }
                        
                        table_obj = {label:"码号类别",show_value:res.detail.number_type == 0 ? '固定码号' : '通道组',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"通道下发地区类型",show_value:res.detail.all_or_prov == 0 ? '全网' : '省网',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否允许带联系方式",show_value:res.detail.is_connect == 0 ? '允许' : '不允许',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否支持纯英文",show_value:res.detail.pure_english_onoff == 0 ? '允许' : '不允许',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否是自选通道",show_value:res.detail.is_own == 0 ? '是' : '不是',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"活跃性开关",show_value:res.detail.active_onoff == 0 ? '打开' : '关闭',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"预警策略",show_value:res.detail.warning_name,name:""};
                        this.tableData_base.push(table_obj);


                        table_obj = {label:"付费类型",show_value:res.detail.channel_fee_type == 0 ? '预付费' : '后付费',name:""};
                        this.tableData_base.push(table_obj);
                        if(res.detail.channel_fee_type == 1){
                            var pay_style = res.detail.pay_style;
                            table_obj = {label:"结算方式",show_value:pay_style == 2 ? '日结' : pay_style == 3 ? '周结' :  pay_style == 4 ? '半月结' : pay_style == 5 ? '月结' : '未知',name:""};
                            this.tableData_base.push(table_obj);
                        }else{
                            table_obj = {label:"通道余额",show_value:res.detail.channel_fee+"元",name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"付款公司",show_value:res.detail.payment_company,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"收款公司",show_value:res.detail.collection_company,name:""};
                        this.tableData_base.push(table_obj);


                        // table_obj = {label:"启动路径",show_value:res.detail.path_start,name:""};
                        // this.tableData_base.push(table_obj);

                        // table_obj = {label:"关闭路径",show_value:res.detail.path_shutdown,name:""};
                        // this.tableData_base.push(table_obj);

                        table_obj = {label:"网关ip",show_value:res.detail.server_ip,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"网关开关",show_value:res.detail.net_work_onoff == 0 ? '手动关闭' : res.detail.net_work_onoff == 1 ? '打开' : '自动关闭',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"频次限制",show_value:res.detail.frequency,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"发送速度",show_value:res.detail.send_speed+"条/秒",name:""};
                        this.tableData_base.push(table_obj);

                        if(send_types.indexOf("1") > -1){
                            show_value = this.send_hhmm_str(res.detail.BeginTime_1)+"到"+this.send_hhmm_str(res.detail.EndTime_1);
                            table_obj = {label:"可发时段-验证码",show_value:show_value,name:""};
                            this.tableData_base.push(table_obj);
                        }
                        if(send_types.indexOf("2") > -1){
                            show_value = this.send_hhmm_str(res.detail.BeginTime_2)+"到"+this.send_hhmm_str(res.detail.EndTime_2);
                            table_obj = {label:"可发时段-行业",show_value:show_value,name:""};
                            this.tableData_base.push(table_obj);
                        }
                        if(send_types.indexOf("3") > -1){
                            show_value = this.send_hhmm_str(res.detail.BeginTime_3)+"到"+this.send_hhmm_str(res.detail.EndTime_3);
                            table_obj = {label:"可发时段-营销",show_value:show_value,name:""};
                            this.tableData_base.push(table_obj);
                        }
                        if(send_types.indexOf("4") > -1){
                            show_value = this.send_hhmm_str(res.detail.BeginTime_4)+"到"+this.send_hhmm_str(res.detail.EndTime_4);
                            table_obj = {label:"可发时段-四类",show_value:show_value,name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"备注",show_value:res.detail.content,name:""};
                        this.tableData_base.push(table_obj);
                        
                    }

                }
                
            });
        },

        //hhmm转中文
        send_hhmm_str(send_hhmm_){
            var send_hhmm_str = "未知点";

            if(API.isEmtry(send_hhmm_)){
                return send_hhmm_str;
            }
            var send_hhmm = send_hhmm_ + "";
            if(send_hhmm.length == 1){
                send_hhmm_str = "0点";
            }else if(send_hhmm.length == 2){
                send_hhmm_str = "0点半";
            }else if(send_hhmm.length == 3){
                var send_hhmm_end_2 = send_hhmm.substring(1);
                if(send_hhmm_end_2 == '00'){
                    send_hhmm_str = send_hhmm.substring(0,1)+"点";
                }else{
                    send_hhmm_str = send_hhmm.substring(0,1)+"点半";
                }
            }else if(send_hhmm.length == 4){
                var send_hhmm_end_2 = send_hhmm.substring(2);
                if(send_hhmm_end_2 == '00'){
                    send_hhmm_str = send_hhmm.substring(0,2)+"点";
                }else{
                    send_hhmm_str = send_hhmm.substring(0,2)+"点半";
                }
            }
            return send_hhmm_str;
        },
        

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>